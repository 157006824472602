import * as React from "react";
import { Seo, Header, ProductsBanner } from "components";
import {
  colors,
  breakpoints,
  Section,
  Container,
  FullWidthContent,
  H4,
  P,
} from "styles";
import styled from "styled-components";
import { graphql } from "gatsby";

const WarningsPage = ({ data }) => {
  return (
    <>
      <Seo title="Warnings & Precautions" />
      <Header
        data={data}
        title="Warnings <br />& Precautions"
        content="<p>Here you will find a list of warnings about<br /> the use of ThermaCare® products.</p>"
      />
      <Section>
        <Container>
          <Warnings>
            <H4>Warnings for use of ThermaCare® heat pads and wraps?</H4>
            <P>
              <strong>DO NOT</strong> microwave or attempt to reheat this
              product to avoid risk of fire. This product has the potential to
              cause skin irritation or burns. Heat discs contain iron (~2 grams)
              which can be harmful if ingested. If ingested, rinse mouth with
              water and call a Poison Control Center right away. If the heat
              disc contents contact your skin or eyes, rinse right away with
              water.
            </P>
            <P>
              <strong>DO NOT</strong> use:{" "}
            </P>
            <ul>
              <li>on broken or damaged skin.</li>
              <li>with medicated lotions, cream, or ointments.</li>
              <li>
                on areas of bruising or swelling that have occurred within 48
                hours.
              </li>
              <li>
                on people unable to remove the product, including children and
                infants.
              </li>
              <li>on areas of the body where the heat that cannot be felt.</li>
              <li>with other forms of heat.</li>
            </ul>
            <P>
              <strong>Ask a doctor</strong> before use if you have diabetes,
              poor circulation, rheumatoid arthritis or are pregnant.
            </P>
            <P>
              <strong>When using this product:</strong>
              <br />
              It is normal to experience temporary skin redness after removing
              the wrap. If your skin is still red after a few hours, stop using
              ThermaCare until the redness goes away completely.
            </P>
            <P>
              <strong>To reduce the risk</strong> of prolonged redness in the
              future, we recommend you:
            </P>
            <ul>
              <li>wear for a shorter period of time.</li>
              <li>wear looser clothing over wrap.</li>
              <li>wear over a thin layer of clothing.</li>
            </ul>
            <P>
              <strong>Periodically check your skin:</strong>
            </P>
            <ul>
              <li>if your skin is sensitive to heat.</li>
              <li>if your tolerance to heat has decreased over the years.</li>
              <li>when wearing a tight fitting belt or waistband.</li>
            </ul>
            <P>
              Consider wearing during the day before deciding to use during
              sleep.
            </P>
            <P>
              <strong>Stop use and ask a doctor if:</strong>
            </P>
            <ul>
              <li>
                after 7 days of product use the pain you are treating gets worse
                or remains unchanged. This could be a sign of a more serious
                condition.
              </li>
              <li>
                you experience any discomfort, swelling, rash or other changes
                in your skin that persist where the wrap is worn.
              </li>
            </ul>
            <P>
              <strong>Keep out of reach of children and pets.</strong>
            </P>
            <br />
            <P>
              For maximum effectiveness, we recommend you wear ThermaCare® for 8
              hours.
            </P>
            <P>
              <strong>DO NOT</strong> use for more than 8 hours in any 24 hour
              period.
            </P>
            <P>
              <strong>If you are over 55:</strong>
              <br />
              The risk of burns increases with age. Therefore, ThermaCare® for
              lumbar spine to lower back and hip should be used over a thin
              layer of clothing and must not be used while you sleep. The skin
              where the product is located should be checked frequently. If
              irritation or signs of burns occur, the product should be removed
              immediately.
              <br />
              <br />
              With age, physical experiences can be reduced or changed. These
              changes can occur because blood flow to the nerve endings, spinal
              cord, or brain is reduced. The spinal cord transmits nerve signals
              and the brain interprets these signals. Therefore, the risk of
              heat-related injuries increases with age. Some people are at risk
              of prolonged redness of the skin or burns.
            </P>
          </Warnings>
        </Container>
      </Section>
      <Section>
        <ProductsBanner />
      </Section>
    </>
  );
};

const Warnings = styled(FullWidthContent)`
  background: ${colors.lightGrey};
  padding: 1.5rem;
  ${H4} {
    margin-bottom: 2em;
  }
  ${P} {
    strong {
      color: ${colors.red};
    }
  }
  ul {
    list-style: none;
    width: 100%;
    margin-left: 4rem;
    margin-bottom: 1rem;
    li {
      list-style-type: disc;
      color: ${colors.brown};
      margin-bottom: 0.5em;
      line-height: 1.4em;
    }
  }
  ${breakpoints.sm} {
    padding: 3rem;
  }
`;

export const query = graphql`
  query WarningsQuery {
    topFlame: file(relativePath: { eq: "background-top-flame.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 90)
      }
    }
    bottomFlame: file(relativePath: { eq: "background-bottom-flame.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 90)
      }
    }
  }
`;

export default WarningsPage;
